//
// Font System
// ===========
// Set a consistent typographical system across the Delivery System.
//
// Each "font" in the system represents a particular setting for all the CSS
// properties that start with "font-" or "text-", plus "line-height" and a few
// others. "Font" does not mean "typeface" (what "font-family" is set to) as
// it's used in colloquial situations. A "font" is a group of specific settings.
//
// The fonts are chosen deliberately to construct a complete typographical system.
// We try to ensure sufficient contrast between typographical roles, consistency
// between typographical roles, and visually harmonious typesetting overall.
// While developing the typographical system, it will be necessary to add,
// remove, or change fonts. In practice, it may be necessary to modify a CSS
// rule for one or a few specific use cases (meaning that components do not
// have to follow the font system exactly, if there is a compelling reason
// to deviate, which is the same for the other systems). Depending on the
// needs of the situation, that may or may not warrant creating a new font
// rule (i.e., a new mixin in this module). When this happens, we need to
// take extra care to maintain an accessible, consistent, and harmonious
// appearance.
//
// The typographical system will include fonts for various elements, such as
// page title, headings, paragraph text, and so on. Yet because the DS spacing
// variables are derived from the font size, and Egret-based websites focus
// strongly on text, the effect of typographical styles extends throughout
// the UI to include widgets, containers, components, and virtually everything.
//

@use "./colors";
@use "./constants";
@use "./spacing";


// Configuration
// =============
// Variables that can be configured on a per-site basis.
//
// When set to "true," the "base font" will be a serif font. Defaults to sans-serif.
$base-font-is-serif: false !default;

// The default 0.01em is a legacy value from MGG/RME. I'm not sure whether it's
// problematic in any way, but for DEUMM I'm going to try setting "normal" as
// the default so the user agent can adjust spacing if required.
$base-letter-spacing: 0.01em !default;

// Set the font-family fallback lists. It would be better for the list to be
// passed in when the module is @use imported, but this doesn't seem easily
// possible because Sass gets confused with the comma-separated font-family
// list, because the variables themselves are also comma-separated.
// --> For all font families, set Bravura Text to the second font family so that,
// if we happen to have a character in the Unicode range for which it's defined,
// then the browser will automatically load and use Bravura Text.
$use-garamond-serif: false !default;
$use-metropolis-sans: false !default;
$use-system-monospace: false !default;

$font-family-list-serif: Skolar, "Bravura Text", Georgia, serif;
@if $use-garamond-serif {
    $font-family-list-serif: "EB Garamond", "Bravura Text", Garamond, ui-serif, serif !global;
}

$font-family-list-sans: SkolarSans, "Bravura Text", Helvetica, Arial, sans-serif;
@if $use-metropolis-sans {
    $font-family-list-sans: Metropolis, "Bravura Text", ui-sans-serif, system-ui, sans-serif !global;
}

$font-family-list-monospace: "Lucida Console", monospace;
@if $use-system-monospace {
    $font-family-list-monospace: ui-monospace, monospace !global;
}


// Non-Configurable Variables
// ==========================
// These can become configurable as needed, but for now they aren't.
//
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$base-font-family: $font-family-list-sans;
@if $base-font-is-serif {
    $base-font-family: $font-family-list-serif !global;
}
$base-font-size: 16px;
$base-word-spacing: normal;



// Minimum spacing for the WCAG AA compliance mode. The DS currently doesn't separate AA and AAA
// compliance for text perception, so the variable names are just for clarity.
// IMPORTANT: These values MUST NOT be changed unless the WCAG standard itself changes.
$wcag-letter-spacing-min-aa: 0.12em;
$wcag-word-spacing-min-aa: 0.16em;


// Font Sizes (Sans Typeface)
// ==========================
//
// Variables for the font sizes set at block level (i.e., on elements that have any value for the
// "display" CSS property other than "inline"). Inline elements may optionally use the "inline font
// sizes" defined below.
//
// -1: 13.5833_px
//  1: 16px
//  2: 21.3_px
//  3: 28.4_px
//  4: 37.925_px
//  5: 50.568px
//  6: 67.424px
//
// For "negative" font sizes (currently only "size -1") we have to cut the factor in half, otherwise
// the resulting text is way too small. *NOTE* that the formula will break if the $font-size-factor
// is not between 1.0 and 2.0 inclusive.
//
$font-size-base: 1rem;
$font-size-factor: 1.333;
$font-size-factor-negative-sizes: 1 - (($font-size-factor - 1) * 0.5);
//
$font-size--1: $font-size-base * $font-size-factor-negative-sizes;
$font-size-1: $font-size-base;
$font-size-2: $font-size-1 * $font-size-factor;
$font-size-3: $font-size-2 * $font-size-factor;
$font-size-4: $font-size-3 * $font-size-factor;
$font-size-5: $font-size-4 * $font-size-factor;
$font-size-6: $font-size-5 * $font-size-factor;

//
// Font sizes for the "UI" mixins. (in submodules/fonts_mixins.scss)
//
$font-size-ui-page-header: $font-size-4;
$font-size-ui-page-subheader: $font-size-3;
$font-size-ui-page-subsubheader: $font-size-2;


// Font Sizes (Serif Typeface)
// ===========================
//
// Set and use a different font size scale for the serif version of _Skolar_ because:
// - The serif typeface looks quite poor at large sizes when compared to _SkolarSans_.
// - The serif typeface is not used in RME, and the sans typeface in MGG is not used
//   everywhere, which suggests it will be okay to have different font size scales.
// - This allows improving the existing MGG font size scale by (1) increasing the
//   minimum font size to 16px, (2) making each scale step consistent, and (3) keeping
//   font sizes relatively similar to the current sizes.
//
$serif-font-size-factor: 1.2;
$serif-font-size-factor-negative-sizes: 1 - (($font-size-factor - 1) * 0.5);
//
$serif-font-size--1: $font-size-base * $serif-font-size-factor-negative-sizes;
$serif-font-size-1: $font-size-base;
$serif-font-size-2: $serif-font-size-1 * $serif-font-size-factor;
$serif-font-size-3: $serif-font-size-2 * $serif-font-size-factor;
$serif-font-size-4: $serif-font-size-3 * $serif-font-size-factor;
$serif-font-size-5: $serif-font-size-4 * $serif-font-size-factor;
$serif-font-size-6: $serif-font-size-5 * $serif-font-size-factor;

//
// Font sizes for the "SUI" mixins. (in submodules/fonts_mixins.scss)
//
$font-size-sui-page-header: $font-size-4;
$font-size-sui-page-subheader: $font-size-3;
$font-size-sui-page-subsubheader: $font-size-2;


//
// ===========================================================================
//
// "ui" Font Styles and Typographic Styles
// ---------------------------------------
//
// Mixins that give base font and typographic styles to use for everything
// in the "UI," which includes everything except "encyclopedic content."
//
// Encyclopedic content always includes article content and shell/preface
// material such as that shown on the /about URLs, but details may change
// from time to time. The exact meaning is determined by the product coordinator
// in consultation with other stakeholders.
//

//
// UI: Basics
// Set font family, weight, line-height, and color (unless `$include-color` is falsy).
//
@mixin font-ui-basics($include-color: true) {
    font-family: $font-family-list-sans;
    font-weight: $font-weight-normal;
    line-height: spacing.$base-line-height;

    @if $include-color {
        color: colors.$main-text;
    }

    @include -internal-wcag_aaa_mode;
}
@mixin -internal-wcag_aaa_mode {
    // This is a mixin so it can be reused by several "font-X-basics" styles.

    html[#{constants.$wcag-enable-attr}="true"] & {
        // Set additional rules to try to meet as many AA/AAA criteria as possible.
        // Note that the phrasing of these criteria means we don't have to set these
        // as the default for all users. Rather, we have to provide a mechanism for
        // these to be set, or we have to guarantee the site doesn't break when
        // these are set.

        // WCAG success criterion 1.4.8:
        // - The inter-paragraph "is at least 1.5 times larger than the line spacing."
        margin-bottom: 1.5rem * spacing.$base-line-height;

        // WCAG success criterion 1.4.8:
        // - Width is no more than 80 characters or glyphs (40 if CJK).
        // NB: The "ch" unit is the width of a zero. However, because of the letter-spacing being set
        //     below, we don't need to account for characters with lesser width.
        max-width: 80ch;
        [lang^="ko"] &,
        [lang^="jp"] &,
        [lang^="zh"] &,
        &[lang^="ko"],
        &[lang^="jp"],
        &[lang^="zh"]
        {
            max-width: 40ch;
        }
    }
}

//
// UI: Page Header
// The main title on a page.
//
@mixin font-ui-page-header {
    @include font-ui-basics;
    font-size: $font-size-ui-page-header;
    font-weight: $font-weight-bold;

    // No "margin-top" because that's usually set by the page itself.
    margin-bottom: spacing.$space-3;
}

//
// UI: Page Subheader
// The sub-titles on a page.
//
@mixin font-ui-page-subheader {
    @include font-ui-basics;
    font-size: $font-size-ui-page-subheader;
    font-weight: $font-weight-bold;

    margin-top: spacing.$space-3;
    margin-bottom: spacing.$space-2;
}

//
// UI: Page Subsubheader
// The sub-sub-titles on a page.
//
@mixin font-ui-page-subsubheader {
    @include font-ui-basics;
    font-size: $font-size-ui-page-subsubheader;
    font-weight: $font-weight-bold;

    margin-top: spacing.$space-2;
    margin-bottom: spacing.$space-1;
}

//
// UI: Body Text
// For paragraphs of text. For example, descriptive text for some functionality.
//
@mixin font-ui-body-text {
    @include font-ui-basics;
    font-size: $font-size-1;
    margin-bottom: spacing.$space-3;
}

//
// UI: Text
// For text that appears outside a paragraph. For example, the <label> of an <input> element.
//
@mixin font-ui-text {
    @include font-ui-basics;
    font-size: $font-size-1;
}

//
// UI: Code Snippet
//
@mixin font-ui-code-snippet {
    font-family: $font-family-list-monospace;
    font-size: $font-size-1;
    line-height: spacing.$base-monospace-line-height;
    margin-top: spacing.$space-2;
    margin-bottom: spacing.$space-2;

    @include -internal-wcag_aaa_mode;
}


//
// ===========================================================================
//
// "sui" Font Styles and Typographic Styles
// ----------------------------------------
//
// Mixins that give base font and typographic styles to use for the "serif UI."
// These are intended for the same use situations as the "ui" Font Styles,
// except with a serifed typeface.
//

//
// SUI: Basics
// Set font family, weight, line-height, and color (unless `$include-color` is falsy).
//
@mixin font-sui-basics($include-color: true) {
    font-family: $base-font-family;
    font-weight: $font-weight-normal;
    line-height: spacing.$base-line-height;

    @if $include-color {
        color: colors.$main-text;
    }

    @include -internal-wcag_aaa_mode;
}

//
// SUI: Page Header
// The main title on a page.
//
@mixin font-sui-page-header {
    @include font-sui-basics;
    font-size: $font-size-sui-page-header;
    font-weight: $font-weight-light;  // We don't load this weight for EB Garamond.
    letter-spacing: normal;

    line-height: spacing.$base-line-height * 0.866;

    // No "margin-top" because that's usually set by the page itself.
    margin-bottom: spacing.$space-3;
}

//
// SUI: Page Subheader
// The sub-titles on a page.
//
@mixin font-sui-page-subheader {
    @include font-sui-basics;
    font-size: $font-size-sui-page-subheader;
    font-weight: $font-weight-bold;

    margin-top: spacing.$space-3;
    margin-bottom: spacing.$space-2;
}

//
// SUI: Page Subsubheader
// The sub-sub-titles on a page.
//
@mixin font-sui-page-subsubheader {
    @include font-sui-basics;
    font-size: $font-size-sui-page-subsubheader;
    font-weight: $font-weight-bold;

    margin-top: spacing.$space-2;
    margin-bottom: spacing.$space-1;
}

//
// SUI: Body Text
// For paragraphs of text. For example, descriptive text for some functionality.
//
@mixin font-sui-body-text {
    @include font-sui-basics;
    font-size: $font-size-1;
    margin-bottom: spacing.$space-3;
}

//
// SUI: Text
// For text that appears outside a paragraph. For example, the <label> of an <input> element.
//
@mixin font-sui-text {
    @include font-sui-basics;
    font-size: $font-size-1;
}


//
// ===========================================================================
//
// DEUMM Font Styles and Typographic Styles
// ----------------------------------------
//
// Mixins developed for DEUMM that correspond to the text styles given by DfC
// in the mockups. These styles can be used on any site where they work well.
//
// Notes:
// - At the start (May 2023) I'm adding mixins for all the styles, along with
//   implementation notes based on the mockups, but the mixins are "empty."
// - The "implementation notes" should be taken lightly. It's much more
//   important to create an internally consistent and harmonious system
//   than to adhere to the mockup specifications.
// - The mixins are not truly empty: they create an outrageous colour
//   combination that indicates the style is not correctly implemented yet.
// - When a style is required, it can be implemented right away. There is no
//   reason to avoid implementing all the styles.
// - They are not being implemented immediately because (1) we don't know that
//   they will all be required, so a full implementation could waste a lot of
//   time, and (2) whereas spacing and sizing must be somewhat different from
//   the DfC suggestion (because the mockups do not consistently apply any
//   font or spacing system) implementation is not trivial, and will require
//   a certain amount of trial and error.
//

//
// outrageous
// This is the default style for all the other styles, until they are
// implemented properly.
//
@mixin -internal-style-outrageous {
    color: yellow !important;
    background-color: black !important;
}

@mixin -internal-style-base($family, $size, $weight, $height) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
    line-height: $height;

    @include -internal-wcag_aaa_mode;
}

//
// sans-base
// Base style rules for anything using the sans font family.
//
@mixin style-sans-base(
    $size: $font-size-1,
    $weight: $font-weight-normal,
    $height: spacing.$base-line-height
) {
    @include -internal-style-base($font-family-list-sans, $size, $weight, $height);
}

//
// serif-base
// Base style rules for anything using the serif font family.
//
@mixin style-serif-base(
    $size: $serif-font-size-1,
    $weight: $font-weight-normal,
    $height: spacing.$base-line-height
) {
    @include -internal-style-base($font-family-list-serif, $size, $weight, $height);
}

//
// h1
//
@mixin style-h1 {
    @include -internal-style-outrageous;
    // font-family: Gotham;
    // font-weight: 350;  // med
    // letter-spacing: -0.01em;  // -1%
    // desktop:
    //     font-size: 44px;
    //     line-height: 48px;  // 1.0909090909090908
    // mobile:
    //     font-size: 36px;
    //     line-height: 48px;  // 1.3333333333333333
}

//
// h2
//
@mixin style-h2 {
    @include -internal-style-outrageous;
    // font-family: Gotham;
    // font-weight: 400;  // bold
    // desktop:
    //     font-size: 34px;
    //     line-height: 40.8px;  // 1.2
    // mobile:
    //     font-size: 28px;
    //     line-height: 33.6px;  // 1.2
}

//
// h3
//
@mixin style-h3 {
    @include -internal-style-outrageous;
    //   font-family: Gotham;
    //   font-weight: 400;  // bold
    // desktop:
    //     font-size: 28px;
    //     line-height: 33.6px;  // 1.2
    // mobile:
    //     font-size: 22px;
    //     line-height: 26.4px;  // 1.2
}

//
// h4
//
@mixin style-h4 {
    @include -internal-style-outrageous;
    // font-family: Gotham;
    // font-weight: 400;  // bold
    // desktop:
    //     font-size: 20px;
    //     line-height: 24px;  // 1.2
    // mobile:
    //     font-size: 18px;
    //     line-height: 21.6px;  // 1.2
}

//
// h5
//
@mixin style-h5 {
    @include -internal-style-outrageous;
    // font-family: Gotham;
    // font-weight: 350;  // medium
    // desktop:
    //     font-size: 14px;
    //     line-height: 16.8px;  // 1.2
    // mobile:
    //     font-size: 12px;
    //     line-height: 14.4px;  // 1.2
}

//
// article-count
// TODO: I don't know where to use this.
//
@mixin style-article-count {
    @include -internal-style-outrageous;
    // font-family: Gotham;
    // font-weight: 400;  // bold
    // desktop:
    //     font-size: 11px;
    //     line-height: 14px;  // 1.2727272727272727
    // mobile:
    //     font-size: 10px;
    //     line-height: 14px;  // 1.4
}

//
// article-numerals
// TODO: I don't know where to use this.
//
@mixin style-article-numerals {
    @include -internal-style-outrageous;
    // font-family: Gotham;  // "Gotham Book?" But we don't have "Metropolis Book."
    // font-weight: 325;
    // desktop:
    //     font-size: 11px;
    //     line-height: 14px;  // 1.2727272727272727
    // mobile:
    //     font-size: 10px;
    //     line-height: 14px;  // 1.4
}

//
// intro
// TODO: This is probably for the intro blurb on the frontpage.
//
@mixin style-intro {
    @include -internal-style-outrageous;
    // font-family: EB Garamond;
    // font-weight: 400;
    // desktop:
    //     font-size: 20px;
    //     line-height: 32px;  // 1.6
    // mobile:
    //     font-size: 18px;
    //     line-height: 24px;  // 1.3333333333333333
}

//
// body-serif
//
@mixin style-body-serif {
    @include style-serif-base($height: 1.7);
    // mobile:
    //     font-size: 14px;
    //     line-height: 20px;  // 1.4285714285714286
}

//
// body-serif-small
//
@mixin style-body-serif-small {
    @include -internal-style-outrageous;
    // font-family: EB Garamond;
    // font-weight: 400;
    // desktop:
    //     font-size: 13px;
    //     line-height: 18px;  // 1.3846153846153846
    // mobile:
    //     font-size: 12px;
    //     line-height: 18px;  // wtf why is this different: 1.5
}

//
// body-serif-smaller
//
@mixin style-body-serif-smaller {
    @include -internal-style-outrageous;
    // font-family: EB Garamond;
    // font-weight: 400;
    // font-size: 11px;
    // line-height: 14px;  // 1.2727272727272727
    // desktop and mobile are the same
}

//
// body-sans
//
@mixin style-body-sans {
    @include style-sans-base();
}

//
// body-sans-bold
//
@mixin style-body-sans-bold {
    @include style-sans-base($weight: $font-weight-bold);
    // I found the heaver weight is difficult to read unless we
    // increase the letter-spacing too.
    letter-spacing: 0.025em;
}

//
// body-sans-small
//
@mixin style-body-sans-small {
    @include style-sans-base($size: $font-size--1);
}

//
// quotes
// This style is for the block quotes on the frontpage.
//
@mixin style-quotes {
    @include -internal-style-outrageous;
    // font-family: Gotham;  // "Gotham Book?" But we don't have "Metropolis Book."
    // font-weight: 325;
    // desktop:
    //     font-size: 30px;
    //     line-height: 36px;  // 1.2
    // mobile:
    //     font-size: 22px;
    //     line-height: 26.4px;  // 1.2
}

//
// deumm-encyclopedia-title
// This is for styling the words so they look like the encyclopedia title
// in the logo. Obviously, you are responsible for using the right words,
// and adding linebreaks where appropriate. It's like this:
// DIZIONARIO / ENCYCLOPEDICO / UNIVERSALE / DELLA MUSICA / E DEI MUISICISTI
//
@mixin style-deumm-encyclopedia-title {
    @include -internal-style-outrageous;
    //- the encyclopedia title:
    //    - Gotham, all caps, 400 weight, 12px size, 20px line height (that's 1.6 repeating)
}

//
// Fonts
// =====
//
// Set standard font styles. "Font" as defined in "fonts_mixins.scss."
//

@use "./systems/colors";
@use "./systems/constants";
@use "./systems/fonts";
@use "./systems/spacing";


html {
    color: colors.$main-text;
    font-family: fonts.$base-font-family;
    font-size: fonts.$base-font-size;
    letter-spacing: fonts.$base-letter-spacing;
    line-height: spacing.$base-line-height;
    word-spacing: fonts.$base-word-spacing;

    // We previously used "font-feature-settings" with "kern, liga, calt" but
    // we are now updating to this more modern way to specify the same. The main
    // benefit is that changing one feature later (for example, turning on
    // small-caps) does not require explicitly repeating the rest of the settings.
    //     I'm not sure it's important to explicitly set these properties to
    // the values that should be default. I'm just continuing the tradition.
    font-kerning: normal;  // kern
    font-variant-ligatures: normal;  // liga, calt

    &[#{constants.$wcag-enable-attr}="true"] {
        // WCAG success criterion 1.4.12:
        // - Line height (line spacing) to at least 1.5 times the font size;
        //   (CRA: We mostly follow this anyway, but set it again here to be sure).
        // - Spacing following paragraphs to at least 2 times the font size;
        //   (CRA: This is accounted for above).
        // - Letter spacing (tracking) to at least 0.12 times the font size;
        // - Word spacing to at least 0.16 times the font size.
        line-height: spacing.$base-line-height !important;
        letter-spacing: fonts.$wcag-letter-spacing-min-aa !important;
        word-spacing: fonts.$wcag-word-spacing-min-aa !important;
    }
}

.monospace {
    @include fonts.font-ui-code-snippet;
}

.nowrap {
    white-space: nowrap;
}

.bold {
    font-weight: fonts.$font-weight-bold;
}

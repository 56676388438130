// Set up the "systems" modules.
@use "set_up_systems";

@import "../../submodules/variables/index";
@import "miscellaneous"; // gives proper MGG $base-font-family definition
@import "../../css_reset";
@import "../../submodules/root_font_rules";
@import "../../submodules/fonts_mixins";

// We have to set $body-bg-color before the "html_and_body" module uses it,
// but after the colours are imported through the "variables" module.
$body-bg-color: $mgg-background-white-color;
@import "../../submodules/html_and_body";


$preload-background-color: $header-top-color;
$preload-border-style: $soft-border-style;
$preload-status-font-color: $header-font-color;


.preload-wrapper {
    @include font-sui-text();
    box-sizing: border-box;

    background: $rilmwhite;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.preload {
    position: fixed;
    background-color: $preload-background-color;
    border: $preload-border-style;
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    left: 50%;
}

// Set padding for the header and status boxes.
.preload-header, .preload-status { margin: $space-4; }
.preload-header { margin-bottom: $space-3; }
.preload-status { margin-top: $space-3; }
.preload-header:empty ~ .preload-status {
    padding-top: 0;
}

.preload-header {
    display: flex;
}

.preload-product-logo {
    margin: auto;
    max-width: 100vw;
}

.preload-status {
    color: $preload-status-font-color;
    text-align: center;

    > * {
        display: inline;
        margin-right: -0.15em;
    }


    > .loading-dot.inactive {
        visibility: hidden;
    }
}

#google-translate-root-wrapper {
    display: none;
    height: 0;
}

// This is the error message for when JavaScript is disabled, like when
// there's a problem loading, or when the browser is too old.
#preload-text-js-disabled {
    text-align: left;

    ol {
        list-style: decimal;
    }
}

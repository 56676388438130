$body-bg-color: $color-rme-outer-bg !default;

html, body {
    max-height: 100vh;
    height: 100%;
}

body {
    background-color: $body-bg-color;
    overflow: auto;
    position: relative;

    &[data-fixed-body] {
        height: 100vh;
        position: fixed;
    }

    &[data-fixed-body="scroll"] {
        overflow-y: scroll;
    }

    &[data-fixed-body="noscroll"] {
        overflow-y: auto;
    }
}

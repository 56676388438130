html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

:focus:not(:focus-visible), :before:focus:not(:focus-visible), :after:focus:not(:focus-visible) {
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition: none !important;
    animation: none !important;
  }
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: none;
}

html {
  color: #1f1f1f;
  letter-spacing: .01em;
  word-spacing: normal;
  font-kerning: normal;
  font-variant-ligatures: normal;
  font-family: Skolar, Bravura Text, Georgia, serif;
  font-size: 16px;
  line-height: 1.5;
}

html[data-egret-force-wcag-text="true"] {
  letter-spacing: .12em !important;
  word-spacing: .16em !important;
  line-height: 1.5 !important;
}

.monospace {
  margin-top: .75rem;
  margin-bottom: .75rem;
  font-family: Lucida Console, monospace;
  font-size: 1rem;
  line-height: 1.3;
}

html[data-egret-force-wcag-text="true"] .monospace {
  max-width: 80ch;
  margin-bottom: 2.25rem;
}

[lang^="ko"] html[data-egret-force-wcag-text="true"] .monospace, [lang^="jp"] html[data-egret-force-wcag-text="true"] .monospace, [lang^="zh"] html[data-egret-force-wcag-text="true"] .monospace, html[data-egret-force-wcag-text="true"] .monospace[lang^="ko"], html[data-egret-force-wcag-text="true"] .monospace[lang^="jp"], html[data-egret-force-wcag-text="true"] .monospace[lang^="zh"] {
  max-width: 40ch;
}

.nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}

html, body {
  height: 100%;
  max-height: 100vh;
}

body {
  background-color: #fff;
  position: relative;
  overflow: auto;
}

body[data-fixed-body] {
  height: 100vh;
  position: fixed;
}

body[data-fixed-body="scroll"] {
  overflow-y: scroll;
}

body[data-fixed-body="noscroll"] {
  overflow-y: auto;
}

.preload-wrapper {
  color: #1f1f1f;
  box-sizing: border-box;
  background: #fefefe;
  width: 100%;
  height: 100%;
  font-family: Skolar, Bravura Text, Georgia, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: fixed;
  top: 0;
  left: 0;
}

html[data-egret-force-wcag-text="true"] .preload-wrapper {
  max-width: 80ch;
  margin-bottom: 2.25rem;
}

[lang^="ko"] html[data-egret-force-wcag-text="true"] .preload-wrapper, [lang^="jp"] html[data-egret-force-wcag-text="true"] .preload-wrapper, [lang^="zh"] html[data-egret-force-wcag-text="true"] .preload-wrapper, html[data-egret-force-wcag-text="true"] .preload-wrapper[lang^="ko"], html[data-egret-force-wcag-text="true"] .preload-wrapper[lang^="jp"], html[data-egret-force-wcag-text="true"] .preload-wrapper[lang^="zh"] {
  max-width: 40ch;
}

.preload {
  background-color: #d9dde8;
  border: 1px solid #97a4bb;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
}

.preload-header, .preload-status {
  margin: 3rem;
}

.preload-header {
  margin-bottom: 1.5rem;
}

.preload-status {
  margin-top: 1.5rem;
}

.preload-header:empty ~ .preload-status {
  padding-top: 0;
}

.preload-header {
  display: flex;
}

.preload-product-logo {
  max-width: 100vw;
  margin: auto;
}

.preload-status {
  color: #315683;
  text-align: center;
}

.preload-status > * {
  margin-right: -.15em;
  display: inline;
}

.preload-status > .loading-dot.inactive {
  visibility: hidden;
}

#google-translate-root-wrapper {
  height: 0;
  display: none;
}

#preload-text-js-disabled {
  text-align: left;
}

#preload-text-js-disabled ol {
  list-style: decimal;
}
/*# sourceMappingURL=preload.css.map */

//
// Egret Delivery System CSS Reset
//
// This is based on the "a11y-css-reset" from https://github.com/mike-engel/a11y-css-reset.
//

// Box-sizing is a bit of a thing.
// The DS started without a box-sizing declaration, thereby defaulting to
// "content-box." Now that we're adding a "border-box" default, some components
// still expect "content-box," so we have to help them along elsewhere.
html {
    box-sizing: border-box;
}
*, *::before, *::after {
    box-sizing: inherit;
}


// Here's the rest of the reset.
html,
body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
}

*:focus:not(:focus-visible),
*::before:focus:not(:focus-visible),
*::after:focus:not(:focus-visible) {
    outline: none;
}

// The idea of putting this rule in the "reset" file is that browsers should
// probably set it this way to begin with.
@media(prefers-reduced-motion: reduce) {
    *, *::before, *::after {
        transition: none !important;
        animation: none !important;
        scroll-behavior: auto !important;
    }
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Re-enable list semantics for Safari with VoiceOver. See blog post for
// further explanation.
// https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
ol, ul {
    list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

// This is part of the Egret DS-specific reset. That is: it's not necessary
// for accessibility, but the rest of the CSS here relies on these properties
// being effectively "zeroed."
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

// Prevent browser-provided quotation marks from showing.
q::before, q::after {
    content: none;
}
